import React from "react";
import { useBase } from "../../../contexts/BaseContext";

const NavSzolg = () => {
  const {navOpen,setNavOpen,settings} = useBase();
  return (
    <div>
        <div class="nav">
      <div class="navsocialswrap">
        <div class="contentwrap">
          <div class="navtelefonszam"><img src="images/call_100dp_3898EC_FILL0_wght400_GRAD0_opsz48.png" loading="lazy" alt="" class="image-7"/>
            <a href="tel:+36706282159" class="text-block-35">70/628-2159</a>
          </div>
          <div class="navemail"><img src="images/mail_100dp_3898EC_FILL0_wght400_GRAD0_opsz48.png" loading="lazy" alt="" class="image-8"/>
            <a href="mailto:katadent77@gmail.com" class="text-block-36">katadent77@gmail.com<br/></a>
          </div>
          
        </div>
      </div>
      <div class="logocontent">
        <div class="telosnavlogo">
          <a href="/" class="logolink w-inline-block"><img src="images/dentistry_100dp_3F7AF6_FILL0_wght400_GRAD0_opsz48.png" loading="lazy" alt="" class="image-9"/>
            <h3 class="heading-30">Katadent</h3>
          </a>
          <div data-w-id="ef994d55-2506-3b9e-ae33-cc1e27339309" class="burger-4" onClick={()=>setNavOpen(!navOpen)}>
            <div class="burger-bar-wrap w-clearfix">
              <div class="b4-bar1"></div>
              <div class="b4-bar-2"></div>
              <div class="b4-bar3"></div>
            </div>
          </div>
        </div>
        {navOpen && <div class="telosnav">
          <a href="/" class="telosnavkezdolap">Kezdőlap</a>
          <a href="/rolunk" class="telosnavrolunk">Rólunk</a>
          <a href="/szolgaltatasok" class="telosnavszolgi">Szolgáltatások</a>
          <a href="/foglalas" style={{textDecoration: "none"}} class="telosnavgomb">
            <div class="telosnavkspcsolat">Foglalás</div>
          </a>
          <div class="telosnavelerhetosegek">
            <a href="tel:+36706282159" class="telosnavteloszam w-inline-block"><img src="images/call_100dp_3898EC_FILL0_wght400_GRAD0_opsz48.png" loading="lazy" alt="" class="image-15"/>
              <div class="text-block-39">70/628-2159</div>
            </a>
            <a href="mailto:katadent77@gmail.com" class="telosnavemail w-inline-block"><img src="images/mail_100dp_3898EC_FILL0_wght400_GRAD0_opsz48.png" loading="lazy" alt="" class="image-14"/>
              <div class="text-block-40">katadent77@gmail.com<br/></div>
            </a>
          </div>
        </div>}
        <div class="tobbilink">
          <a data-w-id="ef994d55-2506-3b9e-ae33-cc1e27339323"  href="/" class="link-11">Kezdőlap</a>
          <a data-w-id="ef994d55-2506-3b9e-ae33-cc1e27339325"  href="/rolunk" class="link-12">Rólunk</a>
          <a data-w-id="ef994d55-2506-3b9e-ae33-cc1e27339327"  href="/szolgaltatasok" aria-current="page" class="link-13 w--current">Szolgáltatások</a>
          <a href="/foglalas" style={{textDecoration: "none"}} class="div-block-37">
            <div class="text-block-37">Foglalás</div>
          </a>
        </div>
      </div>
      
    </div>
    </div>
  );
};

export default NavSzolg;