import React, { useEffect, useRef } from "react";

const Kapcsolat = () => {
  const elementsToObserve = useRef([]);

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.1,
    };

    const callback = (entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add("in-view");
        } else {
          //entry.target.classList.remove("in-view");
        }
      });
    };

    const observer = new IntersectionObserver(callback, options);

    elementsToObserve.current.forEach((el) => {
      if (el) observer.observe(el);
    });

    return () => {
      elementsToObserve.current.forEach((el) => {
        if (el) observer.unobserve(el);
      });
    };
  }, []);

  return (
    <div>
      <div className="kapcsolatstbsection">
        <div className="content">
          <div
            ref={(el) => elementsToObserve.current.push(el)}
            className="szolgaltatas"
          >
            <div className="div-block-2">
              <img
                src="images/dentistry_100dp_FFFFFF_FILL0_wght400_GRAD0_opsz48.png"
                loading="lazy"
                alt=""
                className="image-2"
              />
            </div>
            <div className="div-block-3">
              <h1 className="heading-5">Szolgáltatásaink</h1>
              <p className="text-block-5">Fogszabályozás</p>
              <p className="text-block-2">Konzerváló fogászat</p>
              <p className="text-block-3">Paradontológia</p>
              <p className="text-block-4">Gyermekfogászat</p>
              <p className="text-block-6">Fogékszer ragasztás</p>
            </div>
          </div>
          <div
            ref={(el) => elementsToObserve.current.push(el)}
            className="kapcsolatdiv"
          >
            <div className="kkekkortelefon">
              <img
                src="images/call_100dp_FFFFFF_FILL0_wght400_GRAD0_opsz48.png"
                loading="lazy"
                alt=""
                className="telefonicon"
              />
            </div>
            <div className="kapcsolatleiras">
              <h1 className="kapcsolatheading">Kapcsolat</h1>
              <p className="telefonszam">70/628-2159</p>
              <p className="email">katadent77@gmail.com<br /></p>
            </div>
          </div>
          <div
            ref={(el) => elementsToObserve.current.push(el)}
            className="nyitvatartasdiv"
          >
            <div className="kekkorora">
              <img
                src="images/schedule_100dp_FFFFFF_FILL0_wght400_GRAD0_opsz48.png"
                loading="lazy"
                alt=""
                className="ora"
              />
            </div>
            <div className="nyitvatartasleiras">
              <h1 className="nyitvatartasheading">Nyitvatartás</h1>
              <p className="pentekinyitva">Péntekenként: 14:00 - 19:00</p>
              <p className="szerdainyitva">Kéthetente Szerdán: 9:00 - 13:00</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Kapcsolat;